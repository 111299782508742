<template>
  <div
    class="detection-index"
    :style="{
      background:
        'url(' + require('@/assets/detection_bg.png') + ') no-repeat,#fff',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }"
  >
    <div class="d-header">
      <van-icon @click="$router.go(-1)" name="arrow-left" />
      <span>智能检测</span>
    </div>
    <div
      class="animation"
      @click="$router.push({ path: '/SmartClassroom/Scanning' })"
    >
      <div>一键检测</div>
      <div></div>
      <div></div>
    </div>
    <van-button
      class="enter-btn"
      round
      type="info"
      to="/SmartClassroom/Scanning"
      :color="defineStyle.themeColor"
      >一键检测</van-button
    >
  </div>
</template>
<script>
import { Button } from "vant";
export default {
  components: {
    [Button.name]: Button,
  },
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "../../../style/public.scss";
@keyframes rotateBox {
  0% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes pulse {
  0% {
    width: 100%;
    height: 100%;
  }
  100% {
    width: 180%;
    height: 180%;
  }
}
// @keyframes pulse {
//   0% {
//     box-shadow: 0 0 0 0 #f2f6fc, 0 0 2px 3px themeRGBColor(0.8),
//       0 0 1px 32px #f2f6fc, 0 0 32px 25px themeRGBColor(0.8);
//   }
//   49.9% {
//     box-shadow: 0 0 1px 32px #f2f6fc, 0 0 32px 25px themeRGBColor(0.8),
//       0 0 1px 64px #f2f6fc, 0 0 60px 25px themeRGBColor(0.8);
//   }
//   50% {
//     box-shadow: 0 0 0 0 #f2f6fc, 0 0 2px 3px themeRGBColor(0.8),
//       0 0 1px 32px #f2f6fc, 0 0 32px 25px themeRGBColor(0.8);
//   }
//   100% {
//     box-shadow: 0 0 1px 32px #f2f6fc, 0 0 32px 25px themeRGBColor(0.8),
//       0 0 1px 64px #f2f6fc, 0 0 60px 25px themeRGBColor(0.8);
//   }
// }
.detection-index {
  width: 100%;
  height: 100%;
  z-index: 5;
  position: relative;
  .van-button {
    border-radius: 22px;
    font-size: 15px;
    font-weight: 400;
  }
  .van-button--normal {
    padding: 0 53px;
  }
  .d-header {
    padding-left: 10px;
    padding-top: 14px;
    font-size: 22px;
    font-weight: 400;
    color: $titleColor;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  .animation {
    width: 145px;
    height: 145px;
    background: radial-gradient(#ffc554 0%, themeRGBColor(0.8) 100%);
    box-shadow: 0 0 10px 5px themeRGBColor(0.8);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    > div {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    > div:first-child {
      width: 120px;
      height: 120px;
      border-radius: 50%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      font-weight: 400;
      color: #ffffff;
      z-index: 3;
    }
    > div:first-child::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid themeRGBColor(0.3);
      animation: pulse 2s linear infinite;
    }
    > div:nth-child(2) {
      width: 140px;
      height: 140px;
    }
    > div:nth-child(2)::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid themeRGBColor(0.2);
      animation: pulse 2s linear infinite;
    }
    > div:nth-child(3) {
      width: 170px;
      height: 170px;
    }

    > div:nth-child(3)::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: 2px solid themeRGBColor(0.1);
      animation: pulse 2s linear infinite;
    }
  }
  .enter-btn {
    position: absolute;
    bottom: 123px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>